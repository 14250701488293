import React, { useEffect } from 'react';
import { utcOffsets } from './data/places';
import TimeZoneGroup from './components/timeZoneGroup';
import { useDispatch } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';
import { updateTime } from './redux/timeSlice';

function initializeClock(dispatch: Dispatch) {
  setInterval(() => {
    dispatch(updateTime());
  }, 1000);
}

function App() {
  const dispatch = useDispatch();
  useEffect(() => initializeClock(dispatch), []);

  return (
    <>
      <h1 className="mt-8 font-bold text-2xl md:text-4xl lg:text-6xl font-[Anthela]">
        🍻 Countdown Neujahr 2024 🍻
      </h1>
      <main className="flex flex-col items-center">
        {utcOffsets.map(offset => <TimeZoneGroup key={offset} utcOffset={offset} />)}
      </main>
      </>
  );
}

export default App;

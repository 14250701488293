import { getString } from '../localization';
import { Localization } from '../localization/localization';

export interface Place {
  city?: string;
  countryCode: string;
  utcOffset: number;
}

const places: Place[] = [
  { countryCode:'AD', utcOffset: 1 },
  { countryCode:'AE', utcOffset: 4 },
  { countryCode:'AF', utcOffset: 4.5 },
  { countryCode:'AG', utcOffset: -4 },
  { countryCode:'AL', utcOffset: 1 },
  { countryCode:'AM', utcOffset: 4 },
  { countryCode:'AO', utcOffset: 1 },
  { countryCode:'AR', utcOffset: -3 },
  { countryCode:'AT', utcOffset: 1 },
  { countryCode:'AU', utcOffset: 8, city: 'perth' },
  { countryCode:'AU', utcOffset: 9.5, city: 'darwin' },
  { countryCode:'AU', utcOffset: 10, city: 'brisbane' },
  { countryCode:'AU', utcOffset: 10.5, city: 'adelaide' },
  { countryCode:'AU', utcOffset: 11, city: 'sydney' },
  { countryCode:'AZ', utcOffset: 4 },
  { countryCode:'BA', utcOffset: 1 },
  { countryCode:'BB', utcOffset: -4 },
  { countryCode:'BD', utcOffset: 6 },
  { countryCode:'BE', utcOffset: 1 },
  { countryCode:'BF', utcOffset: 0 },
  { countryCode:'BG', utcOffset: 2 },
  { countryCode:'BH', utcOffset: 3 },
  { countryCode:'BI', utcOffset: 2 },
  { countryCode:'BJ', utcOffset: 1 },
  { countryCode:'BN', utcOffset: 8 },
  { countryCode:'BO', utcOffset: -4 },
  { countryCode:'BR', utcOffset: -3, city: 'brasilia' },
  { countryCode:'BS', utcOffset: -5 },
  { countryCode:'BT', utcOffset: 6 },
  { countryCode:'BW', utcOffset: 2 },
  { countryCode:'BY', utcOffset: 3 },
  { countryCode:'BZ', utcOffset: -6 },
  { countryCode:'CA', utcOffset: -8, city: 'vancouver' },
  { countryCode:'CA', utcOffset: -7, city: 'alberta' },
  { countryCode:'CA', utcOffset: -6, city: 'winnipeg' },
  { countryCode:'CA', utcOffset: -5, city: 'ottawa' },
  { countryCode:'CA', utcOffset: -4, city: 'halifax' },
  { countryCode:'CA', utcOffset: -3.5, city: 'newfoundland' },
  { countryCode:'CD', utcOffset: 1, city: 'kinshasa' },
  { countryCode:'CF', utcOffset: 1 },
  { countryCode:'CG', utcOffset: 1 },
  { countryCode:'CH', utcOffset: 1 },
  { countryCode:'CI', utcOffset: 0 },
  { countryCode:'CL', utcOffset: -3 },
  { countryCode:'CM', utcOffset: 1 },
  { countryCode:'CN', utcOffset: 8 },
  { countryCode:'CO', utcOffset: -5 },
  { countryCode:'CR', utcOffset: -6 },
  { countryCode:'CU', utcOffset: -5 },
  { countryCode:'CV', utcOffset: -1 },
  { countryCode:'CY', utcOffset: 2 },
  { countryCode:'CZ', utcOffset: 1 },
  { countryCode:'DE', utcOffset: 1 },
  { countryCode:'DJ', utcOffset: 3 },
  { countryCode:'DK', utcOffset: 1 },
  { countryCode:'DM', utcOffset: -4 },
  { countryCode:'DO', utcOffset: -4 },
  { countryCode:'DZ', utcOffset: 1 },
  { countryCode:'EC', utcOffset: -5 },
  { countryCode:'EE', utcOffset: 2 },
  { countryCode:'EG', utcOffset: 2 },
  { countryCode:'ER', utcOffset: 3 },
  { countryCode:'ES', utcOffset: 1 },
  { countryCode:'ET', utcOffset: 3 },
  { countryCode:'FI', utcOffset: 2 },
  { countryCode:'FJ', utcOffset: 12 },
  { countryCode:'FM', utcOffset: 11 },
  { countryCode:'FR', utcOffset: 1 },
  { countryCode:'GA', utcOffset: 1 },
  { countryCode:'GB', utcOffset: 0 },
  { countryCode:'GD', utcOffset: -4 },
  { countryCode:'GE', utcOffset: 4 },
  { countryCode:'GH', utcOffset: 0 },
  { countryCode:'GM', utcOffset: 0 },
  { countryCode:'GN', utcOffset: 0 },
  { countryCode:'GQ', utcOffset: 1 },
  { countryCode:'GR', utcOffset: 2 },
  { countryCode:'GT', utcOffset: -6 },
  { countryCode:'GW', utcOffset: 0 },
  { countryCode:'GY', utcOffset: -4 },
  { countryCode:'HN', utcOffset: -6 },
  { countryCode:'HR', utcOffset: 1 },
  { countryCode:'HT', utcOffset: -5 },
  { countryCode:'HU', utcOffset: 1 },
  { countryCode:'ID', utcOffset: 7, city: 'jakarta' },
  { countryCode:'IE', utcOffset: 0 },
  { countryCode:'IL', utcOffset: 2 },
  { countryCode:'IN', utcOffset: 5.5 },
  { countryCode:'IQ', utcOffset: 3 },
  { countryCode:'IR', utcOffset: 3.5 },
  { countryCode:'IS', utcOffset: 0 },
  { countryCode:'IT', utcOffset: 1 },
  { countryCode:'JM', utcOffset: -5 },
  { countryCode:'JO', utcOffset: 2 },
  { countryCode:'JP', utcOffset: 9 },
  { countryCode:'KE', utcOffset: 3 },
  { countryCode:'KG', utcOffset: 6 },
  { countryCode:'KH', utcOffset: 7 },
  { countryCode:'KI', utcOffset: 12, city: 'tarawa' },
  { countryCode:'KI', utcOffset: 14, city: 'tabwakea' },
  { countryCode:'KM', utcOffset: 3 },
  { countryCode:'KN', utcOffset: -4 },
  { countryCode:'KP', utcOffset: 9 },
  { countryCode:'KR', utcOffset: 9 },
  { countryCode:'KW', utcOffset: 3 },
  { countryCode:'KZ', utcOffset: 6, city: 'almaty' },
  { countryCode:'LA', utcOffset: 7 },
  { countryCode:'LB', utcOffset: 2 },
  { countryCode:'LC', utcOffset: -4 },
  { countryCode:'LI', utcOffset: 1 },
  { countryCode:'LK', utcOffset: 5.5 },
  { countryCode:'LR', utcOffset: 0 },
  { countryCode:'LS', utcOffset: 2 },
  { countryCode:'LT', utcOffset: 2 },
  { countryCode:'LU', utcOffset: 1 },
  { countryCode:'LV', utcOffset: 2 },
  { countryCode:'LY', utcOffset: 2 },
  { countryCode:'MA', utcOffset: 1 },
  { countryCode:'MC', utcOffset: 1 },
  { countryCode:'MD', utcOffset: 2 },
  { countryCode:'ME', utcOffset: 1 },
  { countryCode:'MG', utcOffset: 3 },
  { countryCode:'MH', utcOffset: 12 },
  { countryCode:'MK', utcOffset: 1 },
  { countryCode:'ML', utcOffset: 0 },
  { countryCode:'MM', utcOffset: 6.5 },
  { countryCode:'MN', utcOffset: 8, city: 'ulanbator' },
  { countryCode:'MR', utcOffset: 0 },
  { countryCode:'MT', utcOffset: 1 },
  { countryCode:'MU', utcOffset: 4 },
  { countryCode:'MV', utcOffset: 5 },
  { countryCode:'MW', utcOffset: 2 },
  { countryCode:'MX', utcOffset: -8, city: 'mexicali' },
  { countryCode:'MX', utcOffset: -7, city: 'cihuahua' },
  { countryCode:'MX', utcOffset: -6, city: 'mexicocity' },
  { countryCode:'MX', utcOffset: -5, city: 'cancun' },
  { countryCode:'MY', utcOffset: 8 },
  { countryCode:'MZ', utcOffset: 2 },
  { countryCode:'NA', utcOffset: 2 },
  { countryCode:'NE', utcOffset: 1 },
  { countryCode:'NG', utcOffset: 1 },
  { countryCode:'NI', utcOffset: -6 },
  { countryCode:'NL', utcOffset: 1 },
  { countryCode:'NO', utcOffset: 1 },
  { countryCode:'NP', utcOffset: 5.75 },
  { countryCode:'NR', utcOffset: 12 },
  { countryCode:'NZ', utcOffset: 13 },
  { countryCode:'OM', utcOffset: 4 },
  { countryCode:'PA', utcOffset: -5 },
  { countryCode:'PE', utcOffset: -5 },
  { countryCode:'PG', utcOffset: 10 },
  { countryCode:'PH', utcOffset: 8 },
  { countryCode:'PK', utcOffset: 5 },
  { countryCode:'PL', utcOffset: 1 },
  { countryCode:'PS', utcOffset: 2 },
  { countryCode:'PT', utcOffset: 0 },
  { countryCode:'PW', utcOffset: 9 },
  { countryCode:'PY', utcOffset: -3 },
  { countryCode:'QA', utcOffset: 3 },
  { countryCode:'RO', utcOffset: 2 },
  { countryCode:'RS', utcOffset: 1 },
  { countryCode:'RU', utcOffset: 3, city: 'moscow' },
  { countryCode:'RU', utcOffset: 4, city: 'samara' },
  { countryCode:'RU', utcOffset: 5, city: 'yekaterinburg' },
  { countryCode:'RU', utcOffset: 6, city: 'omsk' },
  { countryCode:'RU', utcOffset: 7, city: 'novosibirsk' },
  { countryCode:'RU', utcOffset: 8, city: 'irkutsk' },
  { countryCode:'RU', utcOffset: 9, city: 'yakutsk' },
  { countryCode:'RU', utcOffset: 10, city: 'vladivostok' },
  { countryCode:'RW', utcOffset: 2 },
  { countryCode:'SA', utcOffset: 3 },
  { countryCode:'SB', utcOffset: 11 },
  { countryCode:'SC', utcOffset: 4 },
  { countryCode:'SD', utcOffset: 2 },
  { countryCode:'SE', utcOffset: 1 },
  { countryCode:'SG', utcOffset: 8 },
  { countryCode:'SI', utcOffset: 1 },
  { countryCode:'SK', utcOffset: 1 },
  { countryCode:'SL', utcOffset: 0 },
  { countryCode:'SM', utcOffset: 1 },
  { countryCode:'SN', utcOffset: 0 },
  { countryCode:'SO', utcOffset: 3 },
  { countryCode:'SR', utcOffset: -3 },
  { countryCode:'SS', utcOffset: 2 },
  { countryCode:'ST', utcOffset: 0 },
  { countryCode:'SV', utcOffset: -6 },
  { countryCode:'SY', utcOffset: 2 },
  { countryCode:'SZ', utcOffset: 2 },
  { countryCode:'TD', utcOffset: 1 },
  { countryCode:'TG', utcOffset: 0 },
  { countryCode:'TH', utcOffset: 7 },
  { countryCode:'TJ', utcOffset: 5 },
  { countryCode:'TL', utcOffset: 9 },
  { countryCode:'TM', utcOffset: 5 },
  { countryCode:'TN', utcOffset: 1 },
  { countryCode:'TO', utcOffset: 13 },
  { countryCode:'TR', utcOffset: 3 },
  { countryCode:'TT', utcOffset: -4 },
  { countryCode:'TV', utcOffset: 12 },
  { countryCode:'TW', utcOffset: 8 },
  { countryCode:'TZ', utcOffset: 3 },
  { countryCode:'UA', utcOffset: 2 },
  { countryCode:'UG', utcOffset: 3 },
  { countryCode:'US', utcOffset: -10, city: 'honolulu' },
  { countryCode:'US', utcOffset: -9, city: 'anchorage' },
  { countryCode:'US', utcOffset: -8, city: 'losangeles' },
  { countryCode:'US', utcOffset: -7, city: 'denver' },
  { countryCode:'US', utcOffset: -6, city: 'houston' },
  { countryCode:'US', utcOffset: -5, city: 'washington' },
  { countryCode:'UY', utcOffset: -3 },
  { countryCode:'UZ', utcOffset: 5 },
  { countryCode:'VA', utcOffset: 1 },
  { countryCode:'VC', utcOffset: -4 },
  { countryCode:'VE', utcOffset: -4 },
  { countryCode:'VN', utcOffset: 7 },
  { countryCode:'VU', utcOffset: 11 },
  { countryCode:'WS', utcOffset: 13 },
  { countryCode:'XK', utcOffset: 1 },
  { countryCode:'YE', utcOffset: 3 },
  { countryCode:'ZA', utcOffset: 2 },
  { countryCode:'ZM', utcOffset: 2 },
  { countryCode:'ZW', utcOffset: 2 },
];

const placesByUtcOffset: Map<number, Place[]> = new Map<number, Place[]>();
places.forEach(place => {
  if (!placesByUtcOffset.has(place.utcOffset)) {
    placesByUtcOffset.set(place.utcOffset, []);
  }
  placesByUtcOffset.get(place.utcOffset)!.push(place);
});

export let utcOffsets: number[] = [];
placesByUtcOffset.forEach((_, key) => utcOffsets.push(key));
utcOffsets.sort((a, b) => b - a);

export function getCountryName(place: Place): string {
  const key = 'country.' + place.countryCode;
  return getString(key as keyof Localization);
}

export function getCityName(place: Place): string | undefined {
  if (!place.city) return undefined;

  const key = 'city.' + place.city;
  return getString(key as keyof Localization);
}

export default placesByUtcOffset;

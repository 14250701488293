import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';

export const NEW_YEARS_MIDNIGHT_MILLIS = Date.UTC(2024, 0, 1);

interface SliceState {
  timestamp: number;
}

const initialState: SliceState = {
  timestamp: Date.now(),
};

export const slice = createSlice({
  name: 'time',
  initialState,
  reducers: {
    updateTime: (state) => {
      state.timestamp = Date.now();
    },
  },
});

export const {
  updateTime,
} = slice.actions;

export const selectMillisUntilMidnight = (state: RootState) => {
  return NEW_YEARS_MIDNIGHT_MILLIS - state.time.timestamp;
};

export default slice.reducer;
